@import "variables";
/**
* Include Roboto Condensed font in your project
*
* Download Roboto Condensed ttf files from Google Fonts and place it in the same directory of this CSS file
* You can then use this font in your project by setting
* font-face: "Roboto Condensed", Helvetica, Arial, sans-serif;
*
* @author Mattia Migliorini (deshack)
* @license MIT
*/
/* Light */
@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Condensed Light'), local('RobotoCondensed-Light'), url("#{$font-path}RobotoCondensed-Light.ttf") format('truetype');
}

@font-face {
  font-family: "Roboto Condensed";
  font-style: italic;
  font-weight: 300;
  src: local('Roboto Condensed Light Italic'), local('RobotoCondensed-LightItalic'), url("#{$font-path}RobotoCondensed-LightItalic.ttf") format('truetype');
}
/* Normal */
@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Condensed Regular'), local('RobotoCondensed-Regular'), url("#{$font-path}RobotoCondensed-Regular.ttf") format('truetype');
}

@font-face {
  font-family: "Roboto Condensed";
  font-style: italic;
  font-weight: 400;
  src: local('Roboto Condensed Italic'), local('RobotoCondensed-Italic'), url("#{$font-path}RobotoCondensed-Italic.ttf") format('truetype');
}
/* Bold */
@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'), url("#{$font-path}RobotoCondensed-Bold.ttf") format('truetype');
}

@font-face {
  font-family: "Roboto Condensed";
  font-style: italic;
  font-weight: 700;
  src: local('Roboto Condensed Bold Italic'), local('RobotoCondensed-BoldItalic'), url("#{$font-path}RobotoCondensed-BoldItalic.ttf") format('truetype');
}
