@import "variables";

#content {
  > section {
    margin-top: 50px;

    @media print {
      &:first-child {
        margin-top: 0;
      }
    }

    @media screen and (max-width: $screen-xs-max) {
      margin-top: 25px;
    }
  }
}

#page_main_content {
  margin-bottom: 25px;

  @media screen and (max-width: 600px) {
    padding-left: 0;
    padding-right: 0;
  }
}

#print_header {
  display: none;

  @media print {
    display: block;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;

    body[data-mozPrintCallback] & {
      display: none;
    }
  }
}

#main_navbar {
  .container {
    width: auto;
    max-width: $container-lg;
  }

  .navbar-nav > li.so_funktioniert > a {
    br {
      display: none;
    }
  }
}

#ba_nav_list {
  min-width: 270px;

  @media screen and (max-height: 710px) and (min-width: 600px) {
    -moz-column-count: 2;
    -moz-column-gap: 10px;
    -webkit-column-count: 2;
    -webkit-column-gap: 10px;
    column-count: 2;
    column-gap: 10px;

    > li > a {
      white-space: normal;
      width: 250px;
      text-indent: -25px;
      margin-left: 25px;
    }
  }

  .stadtrat > a {
    font-weight: bold;
  }
}

/* Set widths on the navbar form inputs since otherwise they're 100% wide */
.navbar-form input[type="text"],
.navbar-form input[type="password"] {
  width: 180px;
}
/* Wrapping element */
/* Set some basic padding to keep content from hitting the edges */
.body-content {
  padding-left: 15px;
  padding-right: 15px;

  @media screen and (min-width: $screen-sm-min) {
    padding: 0;
  }
}

#listen_holder {
  &.nur_dokumente {
    .keine_dokumente {
      display: none;
    }
  }

  h2 {
    font-size: 24px;
  }
}

#ris_beta_badge {
  display: block;
  color: #FFF;
  position: absolute;
  right: 0;
  top: 10px;
  background-color: #333;
  width: 150px;
  height: 40px;
  padding: 0;
  text-align: center;
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #FFF;
  border-left: 1px solid #FFF;
  text-decoration: none;

  &:link,
  &:visited {
    text-decoration: none;
  }

  h2 {
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
    margin: 0;
    -webkit-font-smoothing: antialiased;
  }

  p {
    line-height: 12px;
    font-size: 12px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html.csstransforms.csstransforms3d & {
    left: -35px;
    top: 19px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);

    @media screen and (min-width: $screen-sm) {
      left: auto;
      right: -35px;
      top: 19px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
    }
  }
}

.teaser_buttons {
  overflow: hidden;

  a {
    text-align: left;
    display: block;
    overflow: auto;
    padding-right: 10px;
    padding-left: 50px;
    position: relative;
    color: white;

    &:link,
    &:visited {
      color: white;
    }

    .glyphicon {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      font-weight: normal;
      font-size: 30px;
    }

    .paragraph {
      position: absolute;
      left: 10px;
      top: 15px;
      font-size: 30px;
      width: 30px;
      height: 30px;
      display: block;
      vertical-align: top;
      font-weight: bold;
      margin-top: -5px;
      margin-bottom: 10px;
    }

    h2 {
      margin: 0;
      padding: 5px 5px 5px 0;
      font-size: 19px;
      font-weight: bold;
      text-align: left;
    }

    .description {
      float: left;
    }
  }

  @media screen and (max-width: $screen-md-max) {
    .row:before,
    .row:after {
      content: '';
      display: none;
    }
  }

  @media screen and (max-width: $screen-xs-max) {
    overflow: visible;

    a {
      text-transform: none;
      white-space: normal;
      margin-left: -7px;
      margin-right: -7px;
      padding-right: 5px;
    }
  }
}

#stadtratsdokumente_holder {
  position: relative;
  overflow: auto;

  .nur_dokumente & {
    width: 100%;
  }

  .loading_indicator {
    text-align: center;
    font-size: 10em;
    opacity: 0.3;
    position: absolute;
    width: 100%;
    top: 80px;
    left: 0;
    z-index: 100;
  }

  .antragsliste_nav {
    overflow: auto;
  }

  .aeltere_caller {
    font-size: 1.5em;
    margin-top: 1.5em;
    float: right;

    @media print {
      display: none;
    }
  }

  .neuere_caller {
    font-size: 1.5em;
    margin-top: 1.5em;
    float: left;

    @media print {
      display: none;
    }
  }
}

.keine_gefunden {
  color: gray;
  font-style: italic;
}

ul.terminliste2 {
  &.mit_gremienname {
    .list-group-item-heading {
      a:link,
      a:visited {
        color: black;
      }
    }
  }

  &.list-group .list-group-item {
    padding: 0;
    margin-bottom: 20px;
  }

  &.termine_twocols.list-group .list-group-item {
    float: left;
    width: 50%;
    min-height: 155px;
  }

  &.termine_twocols {
    overflow: auto;
  }

  address {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .row-action-primary .glyphicon {
    background: rgba(0, 0, 0, 0.25);
    border-radius: 100%;
    text-align: center;
    line-height: 56px;
    font-size: 20px;
    color: white;
    width: 56px;
    height: 56px;
  }

  .dokumentenliste_small {
    > li {
      line-height: 25px;
    }
  }
}

ul.terminliste {
  list-style-type: none;
  margin: 0;
  padding: 0;

  > li {
    margin: 6px 0 0;
    border: solid 1px #ccc;
    border-radius: 5px;
    background-color: #eee;
    padding: 1px;
    break-inside: avoid-column;
    display: inline-block;
    width: 100%;
    /*overflow: hidden; Sonst zerschießt es die Zeilenumbrüche bei zweispaltigen Listen */
    ul.dokumente {
      border-top: solid 1px #ccc;
    }
  }
}

.abo_button {
  .glyphicon {
    font-weight: bold;
    vertical-align: top;
    font-size: 1.1em;
    margin-top: -3px;
    margin-right: 5px;
  }
}

.abgeordnetenwatch_link {
  float: right;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 3px;
  background-image: url("#{$pics-path}abgeordneten_16.png");

  @media (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
    background-image: url("#{$pics-path}abgeordneten_32.png");
    background-size: 16px 16px;
  }
}

.btn.btn-fab.btn-twitter-link {
  padding-top: 10px;
  padding-left: 8px;
}

.twitter_link,
.fb_link,
.web_link {
  float: right;
  display: inline-block;
  width: 17px;
  height: 17px;
  font-size: 13px;
  font-family: fontello;
}

.twitter_link {
  font-size: 10px;
  padding-top: 2px;
}

.themenverwandt_liste {
  overflow: auto;

  .col-md-4 & {
    padding-left: 9px;
    padding-right: 9px;
  }

  > h2 {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding: 2px;
    margin: 0 0 15px;
  }

  .shariff {
    margin-left: 15px;

    ul {
      width: 240px;
      margin-left: auto;
      margin-right: auto;
    }

    li {
      width: 105px;
    }
  }

  .list-group {
    .list-group-item {
      padding: 0;
      margin: 10px 0;

      a.overflow-fadeout-white {
        max-height: 75px;
        padding-top: 10px;
        border-top: 1px solid rgba(0, 0, 0, .1);
      }

      .row-action-primary {
        padding-top: 10px;

        i {
          line-height: 50px;
          width: 50px;
          height: 50px;
        }
      }

      .metainformationen_antraege {
        float: right;
        color: gray;
        font-size: 12px;
        font-weight: normal;
      }

      .list-group-item-text {
        font-size: 12px;
        font-weight: normal;
        float: left;
      }
    }
  }

  .weitere {
    margin-top: 10px;
    float: right;
    font-size: 16px;
  }
}

.dokumentenliste_small {
  display: block;
  list-style-type: none;
  margin: 0;
  padding: 0;

  > li {
    display: block;
    margin: 0;
    padding: 0;
    line-height: 30px;

    > .metainformationen_antraege {
      float: right;
      color: gray;
      font-size: 12px;
      font-weight: normal;
    }
  }
}

.themenverwandt_liste,
.suchergebnisse {
  a.overflow-fadeout-white {
    font-size: 17px;
    line-height: 22px;
    overflow: hidden;
    display: block;

    > span {
      display: block;
      max-height: 75px;
      position: relative;
      margin-right: -100px;

      &:before {
        content: ' ';
        float: right;
        width: 100px;
        height: 35px;
      }

      &:after {
        content: ' ';
        position: absolute;
        top: 44px;
        right: 84px;
        height: 21px;
        width: 60px;
        /* IE9 SVG, needs conditional override of 'filter' to 'none' */
        background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjcyJSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlZWVlZWUiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+");
        background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 72%, rgba(255, 255, 255, 1) 100%);
        /* FF3.6+ */
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(72%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(255, 255, 255, 1)));
        /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 72%, rgba(255, 255, 255, 1) 100%);
        /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 72%, rgba(255, 255, 255, 1) 100%);
        /* Opera 11.10+ */
        background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 72%, rgba(255, 255, 255, 1) 100%);
        /* IE10+ */
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 72%, rgba(255, 255, 255, 1) 100%);
        /* W3C */
        filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#00428bca', endColorstr='#428bca', GradientType=1);
        /* IE6-8 */
      }
    }
  }
}

@media screen and (min-width: $screen-md) {
  .list-group.two_cols {
    -moz-column-count: 2;
    -moz-column-gap: 10px;
    -webkit-column-count: 2;
    -webkit-column-gap: 10px;
    column-count: 2;
    column-gap: 10px;

    .list-group-item {
      -webkit-column-break-inside: avoid;
      -moz-column-break-inside: avoid;
      -o-column-break-inside: avoid;
      -ms-column-break-inside: avoid;
      column-break-inside: avoid;
      -webkit-break-inside: avoid;
      -moz-break-inside: avoid;
      break-inside: avoid;
      page-break-inside: avoid;
      padding: 0 16px;
    }
  }
}

.suchoptionen {
  .suchkrits_interaktiv {
    > ul {
      font-size: 16px;
      padding-left: 0px;

      > li {
        display: flex;
        align-items: center;

        &:not(:first-child) {
          border-top: thin solid lightgrey;
        }

        .suchkrits_beschreibung {
          width: 100%;
        }

        .fontello-cancel:hover {
            color: darkred;
            text-decoration: none;
        }

        button {
          border: none;
          background: none;
        }

        .glyphicon {
          color: $brand-primary;
        }
      }
    }
  }

  .suchergebnis_eingrenzen {
    > ul {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding-left: 15px;

      li {
        margin-right: 10px;

        button.btn {
          margin: 0px;
          font-size: 18px;
          text-transform: none;
          font-weight: 400;
        }
      }
    }
  }
}

.statistiken,
.ba_infos {
  border: solid 1px #ccc;
  border-radius: 5px;
  background-color: #fff;
  padding: 2px;

  hr {
    height: 0;
    border-top: solid 1px #ccc;
    margin: 0;
    padding: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.start_berichte {
  .weitere {
    float: right;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      border-top: solid 1px #ccc;
      padding: 2px;

      &:first-child {
        border-top: none;
      }
    }
  }
}

#benachrichtigung_einrichten {
  .bestaetigt,
  .email_angegeben,
  .nicht_eingeloggt,
  .wird_benachrichtigt,
  .wird_nicht_benachrichtigt {
    display: none;
  }

  &.wird_benachrichtigt .wird_benachrichtigt {
    display: block;
  }

  &.wird_nicht_benachrichtigt .wird_nicht_benachrichtigt {
    display: block;
  }

  &.bestaetigt {
    .bestaetigt {
      display: block;
    }

    .email_field {
      display: none;
    }

    .submit_row {
      display: none;
    }
  }

  &.email_angegeben .email_angegeben {
    display: block;
  }

  &.nicht_eingeloggt .nicht_eingeloggt {
    display: block;
  }

  #password,
  #bestaetigungscode,
  #bestaetigungscode {
    width: 280px;
  }

  #email {
    width: 230px;
  }

  .wird_nicht_benachrichtigt > div {
    width: 300px;
  }

  .wird_benachrichtigt > div {
    width: 300px;
  }
}

ul.terminliste > li > .termindetails {
  background-color: #fff;
  margin: 0;
  padding: 3px;

  a {}
}

.fraktionen_liste,
.ausschuesse_liste {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;

  > li {
    border-top: solid 1px #ccc;

    &:first-child {
      border-top: none;
    }

    > a {
      display: block;
      padding: 2px;

      .count {
        float: right;
        margin: 2px;
      }

      .glyphicon {
        margin-right: 4px;
      }
    }

    &.closed > ul.mitglieder {
      display: none;
    }

    > ul.mitglieder {
      list-style-type: none;
      margin: 0;
      padding: 0;
      border-top: solid 1px #ccc;
      background-color: #fff;

      > li {
        padding: 2px;

        .zusatzdaten {
          font-size: 0.8em;
          color: gray;
        }
      }
    }
  }
}

.startseite_benachrichtigung_link {
  display: inline-block;
  width: 40px;
  height: 40px;
  font-family: fontello;
  font-size: 30px;
  line-height: 40px;
  vertical-align: middle;
  text-align: center;
  border: solid 1px #5858ff;
  border-radius: 5px;
  color: #5858ff;
  background-color: #eef;

  &.email {
    float: right;
    font-weight: bold;
  }

  &:hover,
  &:active {
    text-decoration: none;
  }

  &:hover {
    background-color: #5858ff;
    color: white;
  }
}

.button_hover_change {
  button {
    width: 100%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .btn-hover {
    display: none;
  }

  &:hover .btn-hover {
    display: inherit;
  }

  &:hover .btn-nohover {
    display: none;
  }
}

.original_ris_link {
  float: right;

  @media print {
    font-size: 12px;
    float: none;

    a[href]:after {
      content: ": " attr(href);
    }
  }
}

.antragsdaten {
  th {
    text-align: center;
  }

  td {
    width: 420px;

    > ul {
      padding-left: 20px;
    }
  }

  .login_modal_form {
    display: inline;

    @media print {
      display: none;
    }
  }

  .tag_add_opener {
    @media print {
      display: none;
    }
  }

  #tag_add_form {
    .selectizejs-control {
      display: inline-block;
      width: 300px;
    }

    .btn {
      display: inline-block;
      margin: 0;
      padding-left: 15px;
      padding-right: 15px;
      vertical-align: top;
    }
  }

  .selectizejs-input {
    height: 36px;
    padding-top: 6px;
  }

  .antrags_tags {
    display: inline-block;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin: 0;
      padding: 0;

      &:before {
        content: ', ';
      }

      &:first-child:before {
        content: '';
      }
    }
  }

  .antrag-herunterladen {
    float: right;

    &:hover {
      text-decoration: none;
    }

    @media print {
      display: none;
    }
  }
}

.termindaten {
  .abgesagt {
    font-weight: bold;
    color: red;
  }
}

.antrag_sidebar {
  @media print {
    display: none;
  }
}

.benachrichtigungsliste {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: table;

  > li {
    margin: 0;
    padding: 0;
    overflow: auto;
    display: table-row;

    > div {
      border-top: solid 1px gray;
      display: table-cell;
      padding: 5px;
      vertical-align: middle;
    }

    &.header > div {
      font-weight: bold;
      border-top: none;
    }
  }
}

.benachrichtigungsliste {
  button.del {
    border: none;
    border-radius: 4px;
    background-color: transparent;
    padding: 0.5em;
    font-size: 1.3em;
    width: 2em;
    height: 2em;
    line-height: 2.2em;
    vertical-align: middle;
    text-align: center;

    &:hover {
      color: red;
    }

    span {
      vertical-align: top;
    }
  }

  > li {
    > .such_holder,
    > .del_holder {
      text-align: center;
    }

    > .krit_holder {
      padding-left: 3em;
      padding-right: 4em;
    }
  }
}

.benachrichtigung_add {
  margin-top: 2em;

  label {
    font-size: 1.3em;
    vertical-align: middle;
    line-height: 1.3em;
    margin-bottom: 0.5em;

    .name {
      font-size: 0.8em;
    }

    > span {
      vertical-align: middle;
    }
  }
}

.benachrichtigung_keine {
  font-size: 1.3em;
}

.benachrichtigung_alle_holder {
  margin-top: 1em;
  overflow: auto;

  > a {
    display: block;
    float: left;
    padding: 0.3em 0.8em;
    margin: 0.5em;
    border: dotted 1px gray;
    border-radius: 3px;
    background-color: #eee;
  }
}

.benachrichtigung_std_button {
  margin-right: 10px;
  margin-left: 10px;
  float: left;

  .glyphicon {
    font-weight: bold;
    font-size: 120%;
    float: left;
    margin-right: 15px;
    margin-left: -15px;
    margin-top: -2px;
  }
}

.form-signin {
  text-align: left;

  fieldset > .form_row {
    padding-top: 0;
    padding-bottom: 15px;
    margin: 0;

    &.checkbox {
      margin-left: -7px;
    }
  }

  &.login #password2 {
    display: none;
  }

  &.login .anlegen {
    display: none;
  }

  &.anlegen .login {
    display: none;
  }
}

.form-docsearch {
  max-width: 800px;
  border: solid 1px black;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
}

textarea {
  padding: 5px;
  border: 1px solid grey;
}
/* keep the footer at the bottom of the page */
$footer_height: 3.1em;
$footer_line_height: 3em;

.over_footer_wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto (-1 * $footer_height);

  @media screen and (max-width: $screen-xs-max) {
    margin: 0 auto (-1.5 * $footer_height);
  }
}

body > footer {
  height: $footer_height;
  background-color: #008276;

  a {
    &:link,
    &:visited {
      color: rgba(255,255,255,0.84);
    }
  }

  p {
    height: $footer_height;
    line-height: $footer_line_height;
    margin-bottom: 0;
    margin-top: 0;
    white-space: nowrap;
  }

  @media print {
    display: none;
  }

  @media screen and (max-width: $screen-xs-max) {
    height: 1.5 * $footer_height;

    p {
      line-height: 0.7 * $footer_line_height;
      height: 1.5 * $footer_height;
    }
  }
}

.footer_spacer {
  height: $footer_height;

  @media screen and (max-width: $screen-xs-max) {
    height: (-1.5 * $footer_height);
  }
}
/* /footer */
section.impressum {
  h2 {
    font-size: 20px;
  }
}

#quicksearch_form {
  .form-control-wrapper {
    float: left;
  }

  @media screen and (max-width: 1100px) {
    .navbar-form input[type="text"],
    .navbar-form input[type="password"] {
      width: 110px;
    }

    .bt {
      padding: 8px;
    }
  }

  button {
    margin-top: -5px;
    margin-bottom: 0;
    margin-left: 3px;

    @media screen and (min-width: $screen-sm) {
      margin-right: 50px; // Wg. #ris_beta_badge
    }
  }

  span.twitter-typeahead {
    .tt-menu {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      display: none;
      float: left;
      min-width: 250px;
      padding: 5px 0;
      margin: 2px 0 0;
      list-style: none;
      font-size: 14px;
      text-align: left;
      background-color: #ffffff;
      border: 1px solid #cccccc;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      background-clip: padding-box;
    }

    .tt-suggestion {
      display: block;
      padding: 3px 10px 3px 20px;
      margin: 5px 0;
      clear: both;
      font-weight: normal;
      line-height: 1.42857143;
      color: $text-color;
      white-space: nowrap;
      font-size: 14px;

      &:hover,
      &:focus {
        color: #ffffff;
        text-decoration: none;
        outline: 0;
        background-color: #88A4A0;
      }
    }

    .input-group & {
      display: block !important;
    }

    .input-group & .tt-dropdown-menu {
      top: 32px !important;
    }

    .input-group.input-group-lg & .tt-dropdown-menu {
      top: 44px !important;
    }

    .input-group.input-group-sm & .tt-dropdown-menu {
      top: 28px !important;
    }
  }

  h3.quicksearch-cat {
    margin: 0 10px 5px;
    padding: 3px 0;
    border-bottom: 1px solid #ccc;
    font-size: 13px;
    color: $text-color;
  }
}

.hyphenate {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.rechtstext {
  .seite {
    margin-left: 50px;
    margin-top: 220px;
    margin-bottom: 420px;
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -ms-transform: scale(1.4);
    -o-transform: scale(1.4);
    transform: scale(1.4);
    font-size: 11px;
    font-family: Arial, Helvetica, sans-serif;
    position: relative;
    top: 0;
    left: 0;
    width: 750px;
    height: 1070px;
    overflow: hidden;
  }
}

.personen_liste {
  .ba_selector {
    padding-left: 0;
    padding-top: 10px;
    margin-left: -4px;
    margin-right: 4px;

    .navbar {
      background-color: #e6e6e6;

      .navbar-nav > li {
        >a:hover,
        >a:focus,
        >a:link,
        >a:visited,
        &.active>a,
        &.active>a:hover,
        &.active>a:focus,
        &.active>a:visited {
          color: black;
        }
      }
    }
  }

  .filter_sorter_holder {
    margin-bottom: 10px;

    @media screen and (min-width: $screen-md-min) {
      width: 575px;
      margin-left: auto;
      margin-right: auto;
    }

    &.extrabreit {
      width: auto;
      margin-left: -15px;
      margin-right: -10px;
    }
  }

  .filter_widget {
    .btn {
      padding-left: 8px;
      padding-right: 8px;
      text-transform: none;
    }

    .btn-separator-left {
      border-left: solid 1px white;
    }

    .btn-separator-right {
      border-right: solid 1px white;
    }
  }

  .sort_widget {
    text-align: left;
    margin-left: 3px;
    display: inline-block;

    a.active:link,
    a.active:visited {
      color: black;
      font-weight: bold;
    }
  }

  .strIn_liste {
    list-style-type: none;
    padding: 0;
    max-width: 575px;
    margin: 0 auto;

    > li {
      display: inline-block;
      width: 180px;
      height: 55px;
      margin: 5px;
      padding: 5px;
      background: #eaeaea url("/images/noisy-texture-100x50-o8-d10-c-dddddd-t1.png");
      border-radius: 3px;
    }

    .partei {
      color: #555;
      font-size: 11px;
    }

    .name {
      font-weight: bold;

      &:link,
      &:visited {
        color: black;
      }
    }

    .sm_links {
      float: right;
      white-space: nowrap;

      a:link,
      a:visited {
        color: #777;
      }
    }
  }

  @media (min-width: $screen-sm-min) {
    .navbar-side .navbar {
      .navbar-collapse {
        padding: 0;
        max-height: none;
      }

      ul {
        float: none;
      }

      li {
        float: none;
        display: block;

        &.stadtrat {
          font-weight: bold;
        }

        a {
          padding-top: 8px;
          padding-bottom: 8px;
        }
      }
    }
  }
}

.personendaten_sidebar {
  dt {
    margin-top: 10px;
  }

  dd {
    margin-bottom: 15px;
  }

  .quelle {
    color: gray;
    font-style: italic;
  }
}

.personentable {
  .mitgliedschaften {
    .inaktiv {
      color: #aaa;
    }
  }
}

.fliesstext {
  h3 {
    margin-top: 40px;
  }
}

a.tooltip_link:link,
a.tooltip_link:visited {
  color: $text-color;
  border-bottom: dotted 1px gray;
}

.glossar {
  .dl-horizontal dt {
    white-space: normal;
  }

  .tooltip-inner {
    max-width: 270px;
  }
}

.dropdown-menu {
  z-index: 10000;
}

.such-liste {
  margin: 0 5px;

  input {
    margin-bottom: 5px;
  }
}

#calendar {
  .fc-event-time {
    flex-basis: 30px;
    flex-shrink: 0;
  }
  .fc-event-title {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .canceled {
    opacity: 0.7;
    .fc-event-title, .fc-event-time {
      text-decoration: line-through;
    }
  }
}

ul.baliste {
  list-style: none;
  font-size: 16px;
  line-height: 1.5;
}

.ba-wahl-dropdown {
  @media screen and (max-width: $screen-xs-max) {
    display: none !important;
  }
}

.ba-wahl-link {
  @media screen and (min-width: $screen-sm-min) {
    display: none !important;
  }
}

@media screen and (max-width: $screen-sm-min) {
  div#content {
    padding: 0;
  }
  // Der selector stammt aus dem bootstrap-code
  body .well,
  .container .well,
  .container-fluid .well,
  body .jumbotron,
  .container .jumbotron,
  .container-fluid .jumbotron {
    box-shadow: none;
    margin-bottom: 1px;
  }

  #content > section {
    margin-top: 1px;
  }
}

.table_sortiert {
  text-align: center;
  /* TODO: Zentrieren nur nach dem Text */
  .sort {
    width: 100%;

    &:after {
      display: inline-block;
      width: 0;
      height: 0;
      content: "";
      position: relative;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }

    &.asc:after {
      top: 4px;
      right: -5px;
      border-top: 5px solid #000;
      border-bottom: 5px solid transparent;
    }

    &.desc:after {
      top: -4px;
      right: -5px;
      border-bottom: 5px solid #000;
    }
  }
}

table li {
  word-break: break-all; // Sonst gibt es bei Dokumententiteln mit lauter Unterstrichen Probleme
}

.tag-liste {
  max-height: 400px;
  overflow-y: scroll;
}
