.map-overlay-info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;

  h4 {
    margin: 0 0 5px;
    color: #777;
  }
}

.map-container {
  /*
  @media screen and (max-width: $screen-xs-max) {
    display: none;
  }
   */
}

#mapholder {
  margin: 0;
  border-radius: 10px;
  overflow: hidden;
  padding: 6px;

  @media print {
    max-width: 600px;
  }

  .leaflet-control-locate .glyphicon {
    margin-top: 4px;
  }
}

#map {
  height: 420px;
}

#benachrichtigung_map {
  height: 400px;
}

#overflow_hinweis {
  padding-left: 5px;
  margin-bottom: 0;
  font-size: 0.9em;
  vertical-align: middle;

  label {
    font-weight: normal;
    vertical-align: middle;
  }

  input[type=checkbox] {
    vertical-align: middle;
    margin-top: 0;
  }

  @media screen and (max-width: $screen-xs-max) {
    display: none;
  }
}

#benachrichtigung_hinweis {
  opacity: 0;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.15s ease;
  -moz-transition: height 0.15s ease;
  -ms-transition: height 0.15s ease;
  -o-transition: height 0.15s ease;
  transition: height 0.15s ease;

  &.half_visible {
    height: 6em;
    -webkit-transition: height 0.15s ease;
    -moz-transition: height 0.15s ease;
    -ms-transition: height 0.15s ease;
    -o-transition: height 0.15s ease;
    transition: height 0.15s ease;
  }

  &.half_visible_tmp1 {
    height: auto;
    min-height: 6em;
  }

  &.half_visible_tmp2 {
    height: 6em;
    -webkit-transition: opacity 0.35s ease;
    -moz-transition: opacity 0.35s ease;
    -ms-transition: opacity 0.35s ease;
    -o-transition: opacity 0.35s ease;
    transition: opacity 0.35s ease;
  }

  &.visible {
    height: auto;
    min-height: 6em;
    padding: 0.5em;
    margin: 0.4em;
    opacity: 1;
    -webkit-transition: opacity 0.35s ease;
    -moz-transition: opacity 0.35s ease;
    -ms-transition: opacity 0.35s ease;
    -o-transition: opacity 0.35s ease;
    transition: opacity 0.35s ease;
    border: solid 1px #ccc;
    background-color: #eee;
    font-size: 0.9em;
    border-radius: 5px;
    text-align: center;
  }
}

.leaflet-popup-content-wrapper {
  border-radius: 10px;
}

.leaflet-popup .ort {
  float: right;
}

.leaflet-popup .ort_dokument {
  border-top: solid 1px #999;
  margin-top: 6px;
  padding-top: 6px;
}

.leaflet-marker-textmarker .text {
  position: absolute;
  z-index: 203;
  font-size: 13px;
  top: 14px;
  left: 0;
  width: 24px;
  text-align: center;
  color: white;
  font-weight: bold;
  text-shadow: 0 0 4px yellow;
  filter:dropshadow(color=yellow, offx=0, offy=0);
}

.leaflet-container:-webkit-full-screen {
  width: 100% !important;
  height: 100% !important;
}

.leaflet-bar,
.leaflet-control-geocoder {
  box-shadow: 0 1px 5px rgba(84, 84, 84, 0.75);
  border-radius: 4px;
}
